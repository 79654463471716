import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Typography} from "@material-ui/core";
import SubHeaderExtract from "./SubHeaderExtract/SubHeaderExtract";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import TableExtract from "./TableExtract";
import MessageUtil from "../../../Utils/MessageUtil";
import ExtractService from "../../../Services/ExtractService";
import MeUtil from "../../../Utils/MeUtil";
import {ExtractContext} from "../../../Contexts/ExtractContext";
import ErrorUtil from "../../../Utils/ErrorUtil";
import DateUtil from "../../../Utils/DateUtil";


class ExtractScreen extends Component {
    static ROUTE = '/extract';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            extractList: [],
            dataFilter: null,
            pagination: null,
            filters: {
                start_at: DateUtil.getDateRangeFormat(7).initial,
                end_at: DateUtil.getDateRangeFormat(7).final,
                companies: null,
                page: 1,
                type: null,
                client: null,
                key: null,
                user_name: null,
            },
        };

        this.extractService = new ExtractService();
    }

    componentDidMount() {
        this.load();
        this.loadDataFilter();
    }

    async loadDataFilter() {
        try {
            this.setState({
                inLoad: true,
            });

            const me = await MeUtil.get();

            const responseFilter = await this.extractService.getFiltersData(me.data.data.company.uuid);

            this.setState({
                inLoad: false,
                dataFilter: responseFilter.data,
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    async load() {
        try {
            if (this.state.filters.start_at > this.state.filters.end_at) {
                throw ErrorUtil.make(
                    'A data inicial não pode ser maior que a final'
                );
            }

            this.setState({
                inLoad: true,
            });

            const me = await MeUtil.get();

            const response = await this.extractService.getByCompany({
                companyUuid: me.data.data.company.uuid,
                queryString: this.makeQueryString(this.state.filters),
            });

            this.setState({
                inLoad: false,
                extractList: response.data.data,
                pagination: response.data,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doSearch = (filter) => {
        this.setState({
            filters: {
                start_at: filter.selectedDateInitialApi,
                end_at: filter.selectedDateFinalApi,
                companies: filter.companies,
                paginate: filter.paginate,
                page: 1,
                type: filter.type,
                client: filter.client,
                key: filter.key,
                user_name: filter.user_name,
            },
        });

        this.load();
    }

    doChangePage = (filter) => {
        this.setState({
            filters: {
                start_at: filter.selectedDateInitialApi ?? this.state.filters.start_at,
                end_at: filter.selectedDateFinalApi ?? this.state.filters.end_at,
                companies: filter.companies ?? this.state.filters.companies,
                paginate: filter.paginate ?? this.state.filters.paginate,
                page: filter.page ?? this.state.filters.page,
                type: filter.type ?? this.state.filters.type,
                client: filter.client ?? this.state.filters.client,
                key: filter.key ?? this.state.filters.key,
                user_name: filter.user_name ?? this.state.filters.user_name,
            },
        });

        this.load();
    }

    makeQueryString = function (obj) {
        var str = [];
        for (let p in obj) {
            if (!obj[p]) {
                continue;
            }

            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }

        }
        return str.join("&");
    }

    render() {
        return (
            <ExtractContext.Provider value={{
                parent: this,
            }}>
                <HeaderComponent title={'Extrato'}
                                 menu={true}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Box width={'80%'}>
                        <Typography align={'center'}
                                    variant={'h6'}
                                    color={'primary'}>
                            Extrato
                        </Typography>
                        <SpacerComponent height={20}/>
                        <SubHeaderExtract/>
                        <SpacerComponent height={40}/>
                        <TableExtract extractList={this.state.extractList}/>
                        {!this.state.extractList.length &&
                            <>
                                <SpacerComponent height={120}/>
                                <Typography variant={'body1'}
                                            align={'center'}>
                                    Não foi encontrado registros para o período
                                    de <b>{DateUtil.raw(this.state.initial).toDate()}</b> à <b>{DateUtil.raw(this.state.final).toDate()}</b>
                                </Typography>
                                <SpacerComponent height={120}/>
                            </>
                        }
                    </Box>
                </Box>
            </ExtractContext.Provider>
        );
    }
}

export default ExtractScreen;